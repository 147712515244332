export const menuData = [

    { title: 'Services', link: '/services'},
    { title: 'Testimonials', link: '/testimonials'},
    { title: 'The Clinic', link: '/location'},
    { title: 'FAQ', link: '/faq'}




]