import Gallery1 from "../images/gallery1.png";
import Gallery2 from "../images/gallery2.png";
import Gallery3 from "../images/gallery3.png";






export const GalleryDataOne = [
  { id: 1, image: Gallery1, description: "Our Location at Ashton House, Moreton" },
  {
    id: 2,
    image: Gallery2,
    description: "The Reception at Ashton House",
  },
  { id: 3, image: Gallery3, description: "The Waiting Area at Ashton House" },
  // { id: 4, image: Gallery4, description: "Onsite Parking" },
  // { id: 5, image: Gallery5, description: "A Patient Receiving Cupping Therapy" },
  // { id: 6, image: Gallery6, description: "photo descrition here" },
  // { id: 7, image: Gallery7, description: "A Patient Receiving Cupping Therapy" },
  // { id: 8, image: Gallery8, description: "A Patient Receiving Cupping Therapy" },
  // { id: 9, image: Gallery9, description: "A Patient Receiving Cupping Therapy" },
  // { id: 10, image: Gallery10, description: "A Patient Receiving Cupping Therapy" },

];
